import React from 'react';
import { Link } from 'gatsby';

import { ROUTES } from 'constants/common';

import { Button } from 'components/UI/Button';

import { Wrapper, Text, Subtitle, Title } from './styled';

interface Props {
  pageText: string;
  pageTitle: string;
}

const NotFoundContent = ({ data }: { data: Props }) => {
  const { pageText, pageTitle } = data;
  return (
    <Wrapper>
      <Title>404</Title>
      <Subtitle>{pageTitle}</Subtitle>
      <Text>{pageText}</Text>
      <Link to={ROUTES.root}>
        <Button>Back to home</Button>
      </Link>
    </Wrapper>
  );
};

export default NotFoundContent;
