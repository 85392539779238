import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import backgroundImage from 'images/background/background.jpg';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import NotFoundContent from 'components/404';

const NotFoundPage = () => {
  const { wpPage } = useStaticQuery(graphql`
    query {
      wpPage(title: { in: "404" }) {
        ...MetaData
        not_found_page {
          pageText
          pageTitle
        }
      }
    }
  `);

  return (
    <Layout backgroundImage={backgroundImage}>
      <SEO metadata={wpPage || {}} />
      <NotFoundContent data={wpPage.not_found_page} />
    </Layout>
  );
};

export default NotFoundPage;
