import styled, { css } from 'styles/styled';

export const textStyles = css`
  font-size: ${({ theme }) => theme.fontSize.smallBase};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.palette.lightDarkOpacity};
`;

export const titleStyles = css`
  font-size: ${({ theme }) => theme.fontSize.titleDesktopBig};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 1.26px;
  color: ${({ theme }) => theme.palette.light};

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.titleDesktopHuge};
  }
`;

export const subtitleStyles = css`
  letter-spacing: 1.1px;
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.palette.lightOpacity};

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.titleDesktopBig};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
`;

export const Title = styled.h1`
  ${titleStyles};
`;

export const Subtitle = styled.h2`
  ${subtitleStyles};
  padding-bottom: 0.5rem;
  text-align: center;
  max-width: 390px;
`;

export const Text = styled.p`
  ${textStyles};
  text-align: center;
  margin-bottom: 1.875rem;
  max-width: 310px;
`;
